import { graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { Seo } from 'components/seo/seo';
import { CmsContext } from 'logic/context/cms';
import { App } from '../app/app';

const PartnersPage: FunctionComponent<any> = ({ data: { contentfulPartnersPage } }) => {
  return (
    <CmsContext.Provider value={contentfulPartnersPage}>
      <Seo
        title={contentfulPartnersPage.pageMeta.metatitle}
        description={contentfulPartnersPage.pageMeta.metadescription}
      />
      <App />
    </CmsContext.Provider>
  );
};

export default PartnersPage;

export const query = graphql`
  query PartnersPage {
    contentfulPartnersPage {
      pageMeta {
        metatitle
        metadescription
      }
      heading
      headingSubtext
      partners {
        ... on ContentfulPartner {
          name
          story
          link
          image {
            fluid(quality: 100, maxWidth: 96, background: "rgb:000000") {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
